@use "./utils/" as *;

.about {
  &__home {
    padding-top: 5.5rem;
    background: url("../../assets/images/about-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    @include flex(center, center);

    @media screen and (min-width: 968px) {
      height: 100vh;
    }

    &__desc {
      text-align: center;
      padding: 5rem 0;

      @include breakpoint-up("medium") {
        padding: 5rem 3rem;
      }
      @include breakpoint-up("large") {
        padding: 5rem;
      }

      @media screen and (min-width: 968px) {
        grid-template-columns: 1fr 1fr;
        padding: 0;
      }
      > div {
        border-left: 4px solid var(--theme-color);

        h3 {
          font-weight: 700;
          color: #333;
          font-size: 2.5rem;
          margin-bottom: 1.5rem;
          padding-left: 1rem;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 2rem;
          letter-spacing: 1px;
          padding-left: 1rem;
        }
      }
    }
  }
}
