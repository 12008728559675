/* ImageZoom.css */
.editor-container {
  width: 100%;
  height: 400px; /* Default height */

  /* Responsive styles */
  @media screen and (max-width: 600px) {
    height: 300px;
  }

  @media screen and (max-width: 400px) {
    height: 200px;
  }
}
.zoom-image {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
  
  .zoom-image img {
    display: block;
  }
  
  .zoom-image .zoom-lens {
    position: absolute;
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: crosshair;
  }
  
  .zoom-image .zoom-preview {
    position: absolute;
    top: 0;
    left: 100%;
    overflow: hidden;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  