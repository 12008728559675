@use "./utils/" as *;

.orderDetails {
  padding-top: 3rem;
  padding-bottom: 7rem;
  font-family: "Roboto", sans-serif;
  @media screen and (min-width: 1080px) {
    padding: 7rem 0;
  }

  @include breakpoint-up("large") {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
  &__container {
    h2 {
      font-size: 1.3rem;
      font-weight: 500;
      color: #333;
      span {
        font-size: 1.1rem;
        @media screen and (min-width: 456px) {
          font-size: 1.3rem;
        }
      }
    }
    &__box {
      margin-bottom: 1.4rem;
      h3 {
        font-size: 1.3rem;
        color: #333;
        font-weight: 500;
        margin-bottom: 1.5rem;
      }

      > div {
        display: flex;
        justify-content: space-between;
      }

      .address {
        margin-right: 2rem;
      }
    }
  }

  &__cartItems {
    h3 {
      font-size: 1.3rem;
      color: #333;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }
    &__container {
      > div {
        @include flex(center, space-between);

        img {
          width: 60px;
        }
      }
    }
  }
}
