@use "./utils/" as *;

.support {
  &__area {
    @include breakpoint-up("medium") {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint-up("xlarge") {
      grid-template-columns: repeat(4, 1fr);
    }
    &__item {
      @include flex(center, flex-start);
      column-gap: 1.5rem;
      &-img {
        width: 45px;
      }
      &__desc {
        display: grid;

        &-title {
          font-size: 16px;
          color: #333;
          margin: 0 0 5px;
          font-weight: 700;
        }

        &-text {
          color: #707070;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
