@use "./utils/" as *;

.myorders {
  padding: 3rem 0;
  font-family: "Roboto", sans-serif;

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 2rem;
  }
  @media screen and(min-width: 1080px) {
    padding: 7rem 0;
  }
}

.order-detail {
  padding: 7px 14px;
  background-color: #2c2c2c;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #fff !important;
  transition: all 0.5s ease;
  &:hover {
    background-color: var(--theme-color);
    color: #fff;
  }
}
