@use "./utils/" as *;

.history {
  padding: 5rem 0;
  gap: 2rem !important;
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem !important;
  }

  &__img {
    max-width: 100%;
  }

  &__content {
    &__text {
      h5 {
        font-size: 18px;
        font-weight: 500;
        color: var(--theme-color);
      }
      h2 {
        font-size: 2rem;
        color: #333;
        font-weight: 600;
        padding-top: 0.5rem;
        padding-bottom: 0.8rem;
      }
      p {
        font-size: 16px;
        color: #2c2c2c;
        font-weight: 400;
      }
    }

    &__customer {
      h1 {
        font-size: 2.5rem;
        font-weight: 600;
        color: var(--theme-color);
      }
      h6 {
        font-size: 18px;
        padding: 0.6rem 0;
        font-weight: 500;
        color: #333;
      }
      p {
        font-size: 15px;
        color: #2c2c2c;
        font-weight: 400;
      }
    }
  }
}
