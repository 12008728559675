@use "./utils/" as *;

.activation {
  height: 100vh;
  @include flex(center, center);

  &__error {
    margin-bottom: 2rem;
    color: red;
    font-weight: 600;
  }

  &__success {
    margin-bottom: 2rem;
    color: var(--theme-color);
    font-weight: 600;
  }

  button {
    width: 200px;
    margin: 0 auto;
  }
}
