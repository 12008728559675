@use "./utils/" as *;

.forgot {
  h3 {
    margin-bottom: 2rem;
  }
  p {
    text-align: center;
    margin-bottom: 2.5rem;
  }
}
