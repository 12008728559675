@use "./utils/" as *;
.modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalWrapper {
  position: relative;
  width: 100%;
  max-width: 960px;
  height: 100%;
  max-height: fit-content;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 6px;
  padding: 0 30px;
  @include breakpoints-down("small") {
    padding: 0;
  }
}

.modalWrapper .close {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  padding: 0;
  z-index: 10;

  svg {
    width: 30px;
    height: 30px;
  }
}

.modal .animate {
  /* transform: translateX(-100%);
    transition: all 2s ease; */
  animation: mymove 0.4s ease;
}

@keyframes mymove {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
