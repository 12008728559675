@use "./utils/" as *;
.allProducts__container__item {
  display: flex;
  align-items: stretch; /* Center vertically */
}

.image-container {
  display: flex;
  justify-content: center; /* Center horizontally */
}

.product-wrap {
  position: relative;
  border: 1px solid #e4e4e4;
  transition: all 0.5s ease;
  font-family: "Roboto", sans-serif;

  &:hover {
    border: 1px solid var(--theme-color);
  
      box-shadow: 0 3px 16px 0 rgba(0,0,0,.11);
      border: 0.5px solid #edc7ba;
 
  }

  .product-img {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }

    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
        display: block;
        margin-bottom: 10px;
        padding: 3px 11px;
        color: #fff;
        border-radius: 3px;
        &.pink {
          background-color: #fa6bff;
        }
        &.purple {
          background-color: var(--theme-color);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .product-action {
      position: absolute;
      z-index: 9;
      bottom: 0;
      left: 37%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 79%;
      transform: translateX(-47%);
      > div {
        @include flex(center, center);
        visibility: hidden;
        height: 48px;
        transition: all 0.5s ease;
        transform: translateY(20px);
        opacity: 0;
        background-color: var(--theme-color);
      }
      .pro-wishlist {
        width: 48px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-cart {
        font-size: 14px;
        width: calc(115.6% - 48px - 47px);
        transition-delay: 0.1s;
        .cart-btn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          margin-right: 0.5rem;
        }
      }
      .pro-quickview {
        width: 49.8px;
        margin: 0;
        transition-delay: 0.2s;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
      .pro-same-action {
        a,
        button {
          font-size: 16px;
          line-height: 48px;
          display: block;
          width: 100%;
          height: 48px;
          text-align: center;
          text-transform: capitalize;
          color: #fff;
          border: none;
          background: none;
          &:hover,
          &.active {
            background-color: #000;
          }
        }
        &.pro-wishlist,
        &.pro-quickview {
          a {
            margin-top: 2px;
          }
        }
      }
    }
  }
  .product-content {
    background-color: #fff;
    // padding: 15px 15px 20px 15px;

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #1b1b1b;
      text-transform: capitalize;
      transition: all 0.5s ease;

      &:hover {
        color: var(--theme-color);
      }
    }
    .product-rating {
      margin: 7px 0;
      svg {
        font-size: 17px;

        margin: 0 3px;

        color: #5f5d5d;
      }
    }
    .product-price {
      span {
        font-size: 18px;
        font-weight: 500;
        color: var(--theme-color);
        position: relative;

        margin: 0 9px;

        &:last-child:before {
          display: none;
        }
        &.old {
          text-decoration: line-through;
          margin-left: 0.5rem;

          color: #8e8e8e;
        }
      }
    }
  }
  &:hover .hover-img {
    visibility: visible;

    transform: translate(-50%, 0);

    opacity: 1;
  }
  &:hover .product-action > div {
    visibility: visible;

    transform: translateY(0);

    opacity: 1;
  }
}

.product-grid-line-view {
  display: flex;
  align-items: center;
  height: fit-content;
  gap: 2rem;
  transition: 0.5s;
  @media screen and (min-width: 769px) {
    gap: 4rem;
    height: 400px;
  }

  @include breakpoints-down("small") {
    flex-direction: column;
  }
  .product-img-grid {
    flex: 1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }

  .product-content {
    flex: 1;
    h3 {
      font-size: 2rem;
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 1.3;
      }
    }
    .product-price {
      span {
        margin: 0.5rem 0;
        font-size: 1.5rem;
        letter-spacing: 0.5px;
        @media screen and (max-width: 600px) {
          font-size: 1.2rem;
        }
      }
    }

    .product-description {
      margin: 1rem 0;
      p {
        font-size: 1rem;
        font-family: "Railway", sans-serif;
        font-weight: 500;
      }
    }

    .buttons-group {
      display: flex;
      align-items: center;
      gap: 1rem;
      .buy-now {
        margin-top: 1rem;
        display: block;
        .cart-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }
        a,
        button {
          font-size: 16px;
          line-height: 48px;
          display: block;

          width: 150px;
          height: 48px;
          text-align: center;
          text-transform: capitalize;
          color: #fff;
          border: none;
          background-color: var(--theme-color);
          &:hover,
          &.active {
            background-color: #000;
          }
        }
      }
      .pro-wishlist {
        a,
        button {
          display: block;
          width: 50px !important;
        }
      }
    }
  }
}

