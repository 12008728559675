.product-price {
    display: flex;
    align-items: center;
    
    .off {
      color: green;
      margin-right: 10px;
    }
    
    s {
      color: gray;
      margin-right: 10px;
    }
  }

  .card-img,
.card-img-top,
.card-img-bottom {
  width: 60%;
  height: auto;

  @media (max-width: 767px) {
    /* Styles for mobile devices */
    width: 60%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    /* Styles for tablets */
    width: 80%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    /* Styles for medium-sized desktops */
    width: 70%;
  }

  @media (min-width: 1200px) {
    /* Styles for large desktops */
    width: 60%;
  }
}
.card-img {
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
      width: 100%;
      height: auto;
    }
  
    @media (max-width: 767px) {
      /* Styles for mobile devices */
      img {
        width: 100%;
      }
    }
  
    /* Add more media queries for different screen sizes if needed */
  }
  .CI{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  
  }

  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.3) ! important;
    padding-left: calc(var(--bs-gutter-x) * 0.3) ! important;
    margin-top: var(--bs-gutter-y);
    flex-direction: row;
    align-items: stretch;
   
}

.view-all-button {
    background-color: white;
    color: red ! important;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 3rem;
    position: relative;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  }

  .LR2{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
  }
  
.featured__products{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}





