.js-image-zoom__zoomed-image {
right:30% ! important;
  // Add other styles as needed
}

/* Add this to your stylesheet or in your component's styles */
.thumbnailD-images {

  gap: 10px; /* Adjust the gap as needed */
}

.thumbnailD-image {
  padding: 4%;
  margin: 9% auto;
  border: 1px solid #f1ede9; /* Light border color */
}

.thumbnailD-image.active {
  border-color: #f35a24; /* Different border color for active thumbnail */
}

.zoomable-image{
  width: 50vw; /* Set the container width to 100% of the viewport width */
  height: 50vh; /* Set the container height to 100% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoomable-imageM{
  width: 99vw; /* Set the container width to 100% of the viewport width */
  height: 50vh; /* Set the container height to 100% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}
.productMainImage{
  max-width: 100%; /* Set the maximum width of the image to 100% of the container */
  max-height: 100%; /* Set the maximum height of the image to 100% of the container */
  width: auto; /* Ensure the image width adjusts based on the container size */
  height: auto; 
}
.product__detail__img {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:2%;
}

.thumbnail-images {
    width: 13%;
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    flex-direction: column; /* Adjust the gap size as needed */
  }
  .thumbnail-imagesM {
    margin-top: 4%;
    width:40%;
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    flex-direction: row; /* Adjust the gap size as needed */
  }

  .thumbnailD-imagesM{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
  }

  .thumbnail-image {
    border: 1px solid #ccc;
    padding: 5px; /* Adjust the padding size as needed */
    cursor: pointer;
  }
  
  .thumbnail-image.active {
    border-color: #f00; /* Apply a different border color for the active thumbnail */
  }
  
  .zoomable-image {
    margin-top: 10px; /* Add margin-top to create space between thumbnail and zoomable image */

  }
  
  .sProdM{
        display: flex ! important;
        align-items: flex-start ! important;
        justify-content: flex-start !important;
        flex-direction: column-reverse !important;
        flex-wrap: nowrap !important;
 
  }
  .underlineD {
    width: 80%;
    height: 1px;
    background-color: #adb5bd;/* Adjust the color as needed */
  }