@use "./utils/" as *;

.discount-section {
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.discount {
  padding: 5rem 0;
  background: url("../../assets/images/discount-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  @include flex(center, flex-start);

  &__content {
    z-index: 10;
    text-align: center;

    h2 {
      color: #fff;
      font-size: 3rem;
      font-weight: 700;
      line-height: 1.3;
      margin-bottom: 1.5rem;

      @include breakpoints-down("medium") {
        font-size: 3rem;
      }
    }

    .button {
      margin: 0 auto;
      width: 200px;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff;
      padding: 0;
      height: 50px;
      line-height: 50px;
      transition: var(--transition);

      &:hover {
        background-color: #f5b01c;
        border: 1px solid #f5b01c;
      }

      @include breakpoints-down("small") {
        width: 170px;
      }
    }
  }
}
