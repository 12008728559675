@use "./utils/" as *;

.product {
  // margin-top: 7rem;
  &__description{
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px; */
    margin-top: 3%;
    padding: 2%;
  }
  &__detail {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    @include breakpoint-up("large") {
      grid-template-columns: 2.3fr 1fr;
    }
    &__img {
      display: grid;
      grid-template-columns: 0.5fr 5fr;
      padding-top: 1%;
      // img {
      //   width: 100%;
      // }
    }

    &__info {
      &-name {
        font-size: 26px;
        margin: 1rem 0 0.5rem 0;
        color: #2c2c2c;
        font-weight: 600;
        text-transform: capitalize;
      }

      &-rating {
        margin-bottom: 1rem;
      }

      &-stock {
        p {
          font-size: 1rem;
          margin-bottom: 1rem;
          font-weight: 400;
        }
      }

      &__price {
        &-count {
          display: block;
          font-size: 22px;
          color: var(--theme-color);
          margin-bottom: 1rem;
          font-weight: 500;
          font-family: "Roboto", sans-serif;
        }
      }
      &__buttons {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        &-quantity {
          @include flex(center, center);
          width: 130px;
          max-width: 100%;
          border: 0.5px solid rgb(177, 177, 177);
          border-radius: 5px;

          @include breakpoints-down("small") {
            width: 110px;
            height: 40px;
          }

          @include breakpoints-down("xm") {
            width: 95px;
          }

          button {
            flex: 1;
            border: none;
            outline: none;
            background: none;
            cursor: pointer;
            font-size: 1.5rem;
            padding: 0.5rem 1rem;

            @include breakpoints-down("small") {
              padding: 0.5rem 0.8rem;
              font-size: 1rem;
            }

            @include breakpoints-down("xm") {
              padding: 0.5rem 0.7rem;
            }
            &:hover {
              background-color: #2c2c2c;
              color: #fff;
            }
          }

          .minus {
            border-right: 0.5px solid rgb(177, 177, 177);
          }

          .add {
            border-left: 0.5px solid rgb(177, 177, 177);
          }

          .input {
            outline: none;
            border: none;
            margin-left: 12px;
            padding: 0;

            @include breakpoints-down("xm") {
              margin-left: 3px;
            }
          }
        }

        &-add {
          button {
            background-color: #0060ab;
            padding: 1rem;
            color: #fff;
            // border-radius: 50px;  //changes
            transition: box-shadow 0.15s ease-out, background-color 0.15s ease-out;
            outline: none;
            overflow: hidden;
          }
        }
        &-buy {
          button {
            background-color: #f35821;
            padding: 1rem;
            color: #fff;
            // border-radius: 50px; //changes
          }
        }

        &-wishlist {
          border: 0.5px solid rgb(177, 177, 177);

          button {
            background-color: transparent;
            padding: 1rem 1.3rem;
            background-color: transparent;

            &:hover {
              background-color: #2c2c2c;
              color: #fff;
            }

            @include breakpoints-down("xm") {
              display: none;
            }
          }
        }
      }

      &-desc {
        margin: 3rem 0;

        p {
          font-size: 16px;
        }
      }
    }
  }

  .product-review-containter {
    margin: 2rem 0;
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    border-top: 1px solid rgba(196, 196, 196, 0.11);
    padding-top: 2rem;
    font-family: "Roboto", sans-serif;

    @media screen and (max-width: 968px) {
      flex-direction: column;
      gap: 2rem;
    }

    .product-review-left {
      width: 100%;

      @media screen and (min-width: 968px) {
        flex: 1;
      }

      h2 {
        font-size: 1.4rem;
        font-weight: 600;
        color: #333;
        font-family: "Roboto", sans-serif;
        margin-bottom: 1.5rem;
      }

      .review-card {
        margin-bottom: 0.5rem;
        .review-content {
          border: 1px solid #ebeeee;
          padding: 16px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          gap: 1rem;

          .review-person {
            display: block;
            width: 48px;
            height: 48px;
            padding: 2px;
            border-radius: 50%;

            border: 1px solid var(--theme-color);
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 40px;
              height: 40px;
            }
          }
          .review-description {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-grow: 1;

            @media screen and (max-width: 500px) {
              flex-direction: column;
            }
            .left {
              @media screen and (max-width: 500px) {
                order: 2;
              }
              h4 {
                color: #262626;
                font-size: 18px;
                text-transform: capitalize;
                margin-bottom: 0;
                @media screen and (max-width: 500px) {
                  font-size: 16px;
                }
              }
              p {
                font-size: 12px;
                margin-bottom: 0.3rem;
              }

              .desc {
                font-size: 14px;
                color: #323232;
              }
            }
            .right {
              @media screen and (max-width: 500px) {
                order: 1;
              }
            }
          }
        }
      }
    }
    .product-review-right {
      width: 100%;

      @media screen and (min-width: 968px) {
        flex: 1;
      }

      h2 {
        font-size: 1.4rem;
        font-weight: 600;
        color: #333;
        font-family: "Roboto", sans-serif;
        margin-bottom: 1.5rem;
      }

      .input-wrapper {
        margin-bottom: 1.3rem;
        label {
          margin-bottom: 5px;
          color: #323232;
          font-size: 16px;
          cursor: default;
          text-transform: capitalize;

          font-weight: 400;
        }

        input {
          border-radius: 4px;
          font-weight: 400;
          transition: 0.4s;

          &:focus {
            border: 1px solid var(--theme-color);
          }
        }
        textarea {
          border-radius: 4px;
          border: 1px solid #ebeeee;
          font-weight: 400;
          transition: 0.4s;

          &:focus {
            border: 1px solid var(--theme-color);
          }
        }
      }

      .review-btn {
        width: 100%;
        width: 160px;
      }
    }
  }
}

.related-products {
  padding-top: 3rem;
  h2 {
    font-weight: 600;
    color: #333;
    font-size: 28px;
    margin-bottom: 3rem;
  }
}


// .related-products.mobile {
//   /* Styles for mobile view */
// }

// .related-products.desktop {
//   /* Styles for desktop view */
//   width: 100%;
//   }

