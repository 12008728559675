@use "./utils" as *;
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
/* hide the scollbar when scrolling useing the mouse right side */
::-webkit-scrollbar {
  width: 0.3rem;
  border-radius: 0.5rem;
  background-color: hsla(var(--second-hue), 8%, 38%);
}

::-webkit-scrollbar-thumb {
  background-color: var(--theme-color);
  transition: var(--transition);
}
html {
  overflow-x: hidden;
}

html,
body {
  height: 100%;
}

body {
  font-family: var(--body-font);
  font-size: rem(14);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  background-color: white;
  color: #2c2c2c;
  @include breakpoints-down("medium") {
    margin-bottom: 3rem;
  }
}

img {
  max-width: 100%;
}

/* ====== global styles any section can i use ====== */

.container-div {
  max-width: 968px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  //min-width: 1024px
  @include breakpoint-up("xlarge") {
    margin: 1rem auto;
  }
  @include breakpoint-up("xxlarge") {
    max-width: 1500px;
  }
}

.grid {
  display: grid;
  gap: 1.25rem;
}

.main {
  overflow: hidden;
}

/*-- Common Style --*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-top: 0;
  color: var(--heading-color);
}

p {
  font-size: rem(14);
  font-weight: 400;
  line-height: rem(24);
  margin-bottom: rem(15);
  color: #333;
}

h1 {
  font-size: rem(36);
  line-height: rem(42);
}

h2 {
  font-size: rem(30);
  line-height: rem(36);
}

h3 {
  font-size: rem(24);
  line-height: rem(30);
}

h4 {
  font-size: rem(18);
  line-height: 24px;
}

h5 {
  font-size: rem(14);
  line-height: rem(18);
}

h6 {
  font-size: rem(12);
  line-height: rem(14);
}

p:last-child {
  margin-bottom: 0;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

a,
button {
  line-height: inherit;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
button {
  border: none;
  outline: none;
}

a,
button,
img,
input,
span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

button,
input[type="submit"] {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
  list-style: outside none none;
}

// button style

.button {
  display: block;
  margin-top: 1rem;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  background-color: #2c2c2c;
  color: #fff;
  padding: 0;
  height: 50px;
  line-height: 50px;
  transition: var(--transition);

  &:hover {
    background-color: var(--theme-color);
    color: #fff;
  }
}

.button-primary {
  display: block;
  margin-top: 1rem;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  background-color: var(--theme-color);
  color: #fff;
  padding: 0;
  height: 50px;
  line-height: 50px;
  transition: var(--transition);
  &:hover {
    background-color: #2c2c2c;
    color: #fff;
  }
}
.button-secondary {
  display: block;
  margin-top: 1rem;
  text-transform: capitalize;
  font-size: 16px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #2c2c2c;
  color: #2c2c2c;
  padding: 0;
  height: 50px;
  line-height: 50px;
  transition: var(--transition);

  &:hover {
    background-color: #2c2c2c;
    color: #fff;
  }
}
.greenColor {
  color: var(--theme-color);
  font-weight: 600;
}
.redColor {
  color: red;
}
.section {
  padding: 5rem 0;
}

/*--
    - Common Classes
-----------------------------------------*/
input {
  font-size: rem(14);
  width: 100%;
  height: 50px;
  padding-left: rem(10);
  color: #2c2c2c;
  border: 1px solid #ebeeee;
  background: transparent;
  box-shadow: none;
}

input:focus {
  background: transparent;
}

textarea {
  font-size: rem(14);
  width: 100%;
  padding: rem(10);
  resize: vertical;
  border: rem(2) solid #eceff8;
  background: #eceff8;
}

textarea:focus {
  border: 1px solid var(--theme-color);
  outline: none;
  background: transparent;
}

/*--
    Scroll Up
-----------------------------------------*/

.scroll-top {
  position: fixed;
  z-index: 9811 !important;
  right: 20px;
  bottom: 60px;
  visibility: hidden;
  overflow: hidden;
  width: 50px;
  height: 50px;
  text-align: center;
  opacity: 0;
  color: #fff;
  border: none;
  border-radius: 50px;
  background: none;
  background-color: var(--theme-color);
  &.show {
    visibility: visible;
    opacity: 1;
  }

  &:hover {
    & i {
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
    }
  }
}

/*--
    - Background Color
------------------------------------------*/

.default-overlay {
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
}

// loader spinner
[data-testid="rings-loading"] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #fff;

  svg {
    width: 400px !important;
    height: 150px !important;
    color: var(--theme-color) !important;
  }
}

/* slider img bounce */

@-webkit-keyframes bounce-img {
  0% {
    transform: translateY(0);

    opacity: 1;
  }
  50% {
    transform: translateY(20px);

    opacity: 1;
  }
  100% {
    transform: translateY(0);

    opacity: 1;
  }
}

@keyframes bounce-img {
  0% {
    transform: translateY(0);

    opacity: 1;
  }
  50% {
    transform: translateY(20px);

    opacity: 1;
  }
  100% {
    transform: translateY(0);

    opacity: 1;
  }
}

@keyframes snow {
  0% {
    background-position: 0 0, 0 0, 0 0;
  }
  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px;
  }
}
.effect-snow {
  animation: snow 20s linear infinite;
}

/*************************
         Margin top
*************************/

.scroll-top {
  position: fixed;
  z-index: 9811 !important;
  right: 20px;
  bottom: 60px;
  visibility: hidden;
  overflow: hidden;
  width: 50px;
  height: 50px;
  text-align: center;
  opacity: 0;
  color: #fff;
  border: none;
  border-radius: 50px;
  background: none;
  background-color: var(--theme-color);
  &.show {
    visibility: visible;

    opacity: 1;
  }

  & i {
    font-size: 22px;
    line-height: 50px;
    display: block;
  }
  &:hover {
    & i {
      animation-name: fadeInUp;
      animation-duration: 1s;
      animation-iteration-count: infinite;

      animation-fill-mode: both;
    }
  }
}

/*--
    - Background Color
------------------------------------------*/
