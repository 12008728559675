@use "./utils/" as *;

.profile {
  padding-top: 2rem;
  margin-bottom: 6rem;
  @media screen and (min-width: 1080px) {
    padding-top: 7rem;
  }
  &__title {
    margin-bottom: 2rem;
    color: #333;
    font-weight: 600;
  }

  &__container {
    @include breakpoint-up("medium") {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
    }
    label {
      margin-bottom: 1rem;
      font-weight: 600;
      color: #2c2c2c;
    }

    input:disabled {
      background-color: #ececec;
    }
    &__img {
      width: 150px;
      height: 150px;
      overflow: hidden;
      position: relative;
      margin: 15px auto;
      border: 1px solid #ddd;
      border-radius: 50%;
      cursor: pointer;
      text-align: start;

      &:hover span {
        bottom: -15%;
        color: #fff;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }

      span {
        position: absolute;
        color: #ffff;
        bottom: -100%;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: 10;
        text-align: center;
        font-weight: 400;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        background-color: var(--theme-color);

        p {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
        }
      }
    }

    &__info {
      input {
        border: 1px solid #ececec;
        border-radius: 5px;
        color: #2c2c2c;
        font-weight: 500;
        font-size: 15px;
      }
      &-name {
        margin-bottom: 1.2rem;
      }
    }

    &__password {
      &-warning {
        display: block;
        color: red;
        font-size: 15px;
        margin-bottom: 1rem;
      }
      &-pass {
        margin-bottom: 1.2rem;
        position: relative;
        small {
          position: absolute;
          top: 70%;
          right: 15px;
          transform: translateY(-50%);
          cursor: pointer;
          opacity: 0.5;
          svg {
            font-size: 16px;
          }
        }
      }

      &-confirm {
        position: relative;
        small {
          position: absolute;
          top: 70%;
          right: 15px;
          transform: translateY(-50%);
          cursor: pointer;
          opacity: 0.5;
          svg {
            font-size: 16px;
          }
        }
      }
    }
  }

  &__button {
    width: 160px;
    margin-bottom: 3rem;
  }
}
