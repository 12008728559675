

.product__detail__info__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: inherit;
  align-items: stretch ! important;
}

.product__detail__info__buttons-add,
.product__detail__info__buttons-buy {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.product__detail__info__buttons-buy button{
  width:100%;
}

.button-add,
.button-buy,
.button-disabled {
  font-size: 16px; /* Default font size */
  white-space: nowrap; /* Prevent line breaks */
}

@media screen and (max-width: 767px) {
  .button-add,
  .button-buy,
  .button-disabled {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .button-add,
  .button-buy,
  .button-disabled {
    font-size: 12px; /* Adjust font size for even smaller screens */
  }
}



.prod-master{
    -webkit-box-shadow: 8px -3px 5px -4px rgba(212,208,212,0.76);
    -moz-box-shadow: 8px -3px 5px -4px rgba(212,208,212,0.76);
    box-shadow: 8px -3px 5px -4px rgba(212,208,212,0.76);
.productS {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    margin-top:0px;

    .product-price {
        display: flex;
        align-items: center;
        
        .off {
          color: green;
          margin-right: 10px;
        }
        
        s {
          color: gray;
          margin-right: 10px;
        }
      }
    
    .product-thumbnail {
      flex: 0 0 auto;
      margin-right: 10px;
      
      img {
        max-width: 100px;
        height: auto;
      }
    }
    
    .product-details {
      flex: 1 1 auto;
      
      .product-name {
        font-size: 20px;
        font-weight: bold;
      }
      
      .product-description {
        margin-top: 5px;
        color: #555;
      }
      
      .product-attributes {
        margin-top: 10px;

          .attribute {
            font-weight: bold;
            padding: 1%;
            border: 0.5px solid #d6d2d2;
            margin: 1%;
          } 
        
      }
    }
  }
}

  .buttons-groupS {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .buy-nowS,
    .buy-now.pro-wishlistS {
      flex: 1;
      margin-right: 10px;
      
      button {
        width: 100%;
        margin-top: 10%;
        padding: 3%;
      }
    }
    
    .buy-nowS {
      .cart-btnS {
        background-color: #1e90ff;
        color: #fff;
        font-size: 1.1rem;
      }
      
      .active {
        background-color: #ccc;
        color: #fff;
      }
    }
    
    .pro-wishlistS {
      button {
        background-color: transparent;
        border: none;
      }
    }
  }

  .category-slider {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    /* Add any additional styles for the slider container */
  }
  
  .category-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    /* Add any additional styles for each category slide */
  }
  
  .category-icon {
    font-size: 24px;
    color: #000;
    width: 15%;
    /* Add any additional styles for the category icon */
  }
  
  /* Hide scrollbar */
  .category-slider::-webkit-scrollbar {
    display: none;
  }
  
  .product__detail__info__buttons-add button,
.product__detail__info__buttons-buy button {
  /* Add your button styles here */
  // background-color: var(--theme-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.3s;
  // border-radius: 20px;
  background: rgba(black, .1)
}

.product__detail__info__buttons-add button:hover,
.product__detail__info__buttons-buy button:hover {
  padding-right: 25px;
  background: rgba(black, 0.2)
}


.product__detail__info__buttons-add button:disabled,
.product__detail__info__buttons-buy button:disabled {
  background-color: #ccc;
  cursor: pointer;
}

.product__detail__info__buttons-buy {
  margin-top: 10px;
  text-align: right;
}

.product__detail__info__buttons-add {
  margin-top: 10px;
  text-align: left;
}