@use "./utils/" as *;

.shop-main {
  padding-top: 4.5rem;
  width: 100%;
  @include breakpoint-up("xlarge") {
    padding-top: 5.5rem;
  }

  .title {
    height: 160px;
    background-color: rgb(243, 244, 246);
    @include flex(center, center);

    > div {
      text-align: center;
    }
    h2 {
      font-weight: 600;
      color: #333;
      font-size: 3rem;
    }

    .breadcramp {
      margin-top: 0.7rem;
      a {
        font-size: 0.9rem;
        font-weight: 500;
        color: rgb(116, 116, 116);
        transition: 0.4s;

        &:hover {
          color: var(--theme-color);
        }
      }

      .arrow {
        margin: 0 0.5rem;
      }

      span {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }
}

.shop {
  padding-top: 1rem;
  padding-bottom: 1rem;
  &__filter {
    padding-bottom: 3rem;

    @include breakpoint-up("large") {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 968px) {
      grid-template-columns: 1fr 1fr 1fr 90px;
      gap: 2rem;
    }
    &__category {
      @include flex(center, space-between);

      option {
        font-weight: 500;
        padding: 8px 0;
        font-size: 0.9rem;
      }

      p {
        margin-bottom: 0;
        font-weight: 600;
        color: #333;
        font-size: 16px;
      }

      select {
        padding: 0.5rem 1rem;
        width: 70%;
        border: 1px solid #e4e4e4;
        color: #2c2c2c;
        border-radius: 3px;
        transition: all 0.5s ease;
        @include breakpoint-up("large") {
          width: 75%;
        }

        &:focus {
          border: 1px solid var(--theme-color);
        }
      }
    }
    &__searchHome {
      position: relative;
      height: 36px;
      width: 100%;

      input {
        position: absolute;
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        border: 1px solid #ebeeee;
        padding: 0 15px 0 45px;
        color: #333;
        font-size: 16px;
        font-weight: 500;
        border-radius: 6px;
        transition: all 0.5s ease;

        &:focus {
          border: 1px solid var(--theme-color);
        }
      }
      svg {
        position: absolute;
        z-index: 2;
        color: #333;
        top: 50%;
        right: 1%;
        font-size: 25px;
        transform: translateY(-50%);
      }
    }
    &__search {
      position: relative;
      height: 36px;
      width: 100%;

      input {
        position: absolute;
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        border: 1px solid #ebeeee;
        padding: 0 15px 0 45px;
        color: #333;
        font-size: 16px;
        font-weight: 500;
        border-radius: 6px;
        transition: all 0.5s ease;

        &:focus {
          border: 1px solid var(--theme-color);
        }
      }
      svg {
        position: absolute;
        z-index: 2;
        color: #333;
        top: 50%;
        right: 1%;
        font-size: 25px;
        transform: translateY(-50%);
      }
    }
  }

  .grid-view-container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;

    @include breakpoint-up("large") {
      justify-content: flex-end;
    }

    .grid-active {
      svg {
        font-size: 20px;
        color: var(--theme-color);
        cursor: pointer;
      }
    }

    .grid-icon {
      svg {
        font-size: 20px;
        color: #444;
        cursor: pointer;
      }
    }
  }
}

.pagination {
  gap: 1rem;
  margin-top: 4rem;
}

.page-item:first-child .page-link {
  color: var(--theme-color);
  box-shadow: none;
}

.page-item:last-child .page-link {
  color: var(--theme-color);
  box-shadow: none;
}
.page-item.disabled .page-link {
  color: #6c757d !important;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
  box-shadow: none;
}

.page-item.active .page-link {
  background-color: var(--theme-color) !important;
  color: #fff;
  border: 1px solid var(--theme-color);
  &:focus {
    border: none;
    box-shadow: none;
  }
}

.page-link {
  color: #333;
  padding: 11px 22px;
}
