.category-icon-slide {
  margin: 0% 1% 1% 1%;
  /* margin-left: 3%; */
  /* margin-right: 0%; */
  /* padding: 1% 1% 0 1%; */
  display: flex;
  overflow-x: auto;
  max-width: 96%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-around;

  }
  .carousel-control-prev,
.carousel-control-next {
  color: #ffffff; /* Set the desired color for the controls */
  background-color: #000000; /* Set the desired background color for the controls */
}

  .category-icon {
    flex: 0 0 auto;
    margin-top: -1%;
    /* Add additional styling as needed */
  }

  .CMain {
    margin-top: calc(var(--header-height) + 20px);
  }
 

::-webkit-scrollbar-thumb {
  background-color: white !important;

  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

::-webkit-scrollbar {
    background-color: white ! important;
}
::-webkit-scrollbar {
    background: none;
}