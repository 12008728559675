@use "./utils/" as *;
.hero-section {
  width: 100%;
  background-color: rgb(224, 224, 224);
  color: #fff;
  // margin-top: var(--header-height);

  @include breakpoint-up("medium") {
    height: 52vh;
    width: 100%;
  }
}

.hero-slide__item {
  width: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem 0;

  @include breakpoint-up("large") {
    padding: 9rem 0;
  }
  @include breakpoint-up("xlarge") {
    height: 100vh;
  }
  

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: relative;

    &__info {
      width: 100%;

      position: relative;

      @include breakpoint-up("large") {
        width: 55%;
      }

      & > * ~ * {
        margin-top: 2rem;
      }

      .title {
        font-weight: 700;
        line-height: 1.3;
        font-size: 2.5rem;
        color: #fff;
        margin-top: 1.2rem;
        @include breakpoint-up("medium") {
          color: #272727;
        }
        @include breakpoint-up("xlarge") {
          font-size: 4rem;
        }
      }

      .overview {
        font-weight: 700;
        font-size: 22px;
        color: #f3b632;
      }

      .btns > * ~ * {
        margin-left: 1rem;
      }

      .btns,
      .title,
      .overview {
        opacity: 0;
        transform: translateY(-100px);
        transition: transform 0.5s ease, opacity 0.5s ease;
      }

      .shop-now {
        width: 200px;
        text-transform: capitalize;
        font-size: 16px;
        background-color: #f3b632;
        color: #fff;
        padding: 0;
        height: 50px;
        line-height: 50px;
        transition: var(--transition);

        &:hover {
          background-color: #f5b01c;
        }

        @include breakpoints-down("small") {
          width: 170px;
        }
      }
    }
  }

  &.active > &__content > &__content__info {
    .btns,
    .title,
    .overview {
      opacity: 1;
      transform: translateY(0);
    }

    .title {
      transition-delay: 0.3s, 0.3s;
    }

    .overview {
      transition-delay: 0.6s, 0.6s;
    }

    .btns {
      transition-delay: 0.9s, 0.9s;
    }
  }
}
