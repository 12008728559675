@use "./utils/" as *;
.box {
  /* Your existing styles for the box */
  overflow: hidden;
  transition: box-shadow 0.3s;
}
.container-inherit-width {
  width: inherit;
}
.desktop-box {
  /* Box styles for desktop view */
  display: flex;
  flex: auto;
  text-align: center;
  width: 20vw;
  height: 40vh;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
}.box:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transform: scale(1.05); /* Optional: Add a slight scale effect on hover */
}

.mobile-box {
  /* Box styles for mobile view */
  display: flex;
  flex: auto;
  text-align: center;
  width: 41vw;
  height: 25vh;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: center;
}


.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-white);
  z-index: 300;
}
.bannerSection{
  height:52vh;
}
.bannerSlider{
  height:100%;
}
.nav {
  height: var(--header-height);
  display: grid;
  grid-template-columns: 0.3fr 0.2fr 1fr 0.5fr;
  justify-items: end;
  align-items: center;

  @include breakpoints-down("medium") {
    margin: 0.5rem 1rem;
  }

  &__logo {
    @include flex(center, center);
    height: inherit;
    width: inherit;
    
  }

  &__menu {
    @include breakpoints-down("medium") {
      position: fixed;
      background-color: blue;
      top: 0;
      right: -100%;
      width: 100%;
      height: 100vh;
      padding: 6rem 2rem 3.5rem;
      transition: var(--transition);
      background-color: var(--color-grey);
      background-size: cover;
      background-repeat: repeat;
      z-index: 999;
    }

    &__list {
      .active {
        color: var(--theme-color);
      }
      li {
        padding: 5px 0;
        font-weight: 700;
        position: relative;
        a {
          font-weight: 600;
          transition: all 0.5s ease;
          &:hover {
            color: var(--theme-color);
          }
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          transition: width 0.5s ease;
          height: 2px;
          background-color: var(--theme-color);
        }

        &:hover::after {
          width: 100%;
        }
      }

      @include breakpoints-down("medium") {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
      }
    }
  }

  &__icons {
    @include breakpoints-down("xs") {
      padding: 1rem 1.5rem;
    }

    @include breakpoints-down("medium") {
      position: fixed;
      bottom: 0;
      background-color: var(--color-grey);
      width: 100%;
      box-shadow: 0 0 6px rgb(0 0 0 / 10%);
      padding: 1rem 2.5rem;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    li {
      a {
        font-size: 1.2rem;
      }
    }

    //
  }

  &__list {
    @include flex(center, space-between);
  }
  &__item {
    @include breakpoint-up("xlarge") {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__link {
    padding: 0.10rem;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: left;
    text-transform: capitalize;

  }

  &__close {
    font-size: 2rem;
    position: absolute;
    top: 0.9rem;
    right: 1.25rem;
    cursor: pointer;
    svg {
      width: 35px;
      height: 35px;
      color: #2c2c2c;
      margin: 0.5rem 1rem;

      @include breakpoint-up("xlarge") {
        display: none;
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    @include breakpoint-up("xlarge") {
      display: none;
    }

    &__toggle {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      position: relative;
      @include flex(center, center);
      background-color: var(--color-white);

      svg {
        width: 75%;
        height: 75%;
      }
      @include breakpoint-up("xlarge") {
        display: none;
      }
    }
  }

  &__mobileMenu {
    @include breakpoint-up("xlarge") {
      display: none;
    }
  }

  
  &__wrapper {
    position: relative;

    span {
      font-size: 15px;
      font-weight: 600;
      position: absolute;
      top: -7px;
      right: -8px;
      background-color: var(--theme-color);
      width: 23px;
      height: 23px;
      border-radius: 50%;
      @include flex(center, center);
      color: #fff;
    }
  }

  &__dropdown {
    &__item {
      &:focus {
        background-color: transparent !important;
      }
    }
    &-icon {
      color: #2c2c2c;
      font-size: 1.2rem;
    }
  }
}

.scroll-header {
  box-shadow: 0 2px 4px hsla(0, 4%, 15%, 0.1);
}
/* Show menu */
.show-menu {
  right: 0;
}

// override default style
.dropdown-menu.dropright {
  top: 0;
  left: 100%;
  margin-top: 0;
  margin-left: 1.125em;
  min-width: 10rem; /* Optional: Set the minimum width for the dropright menu */
}
.dropdown-menu.dropright .dropdown-menu {
  top: 0;
  left: 120%;
  margin-top: -1px;
  margin-left: 0.125rem;
}

.dropdown-menu {
  border: none;
  box-shadow: 0 0 10px 0 #e6e5ea;
  padding: 10px;
  color: #2c2c2c;
}
.dropdown-item {
  padding: 0.5rem 1rem;
}
.nav-link {
  // color: #2c2c2c;      //color change
  font-weight: 500;
  font-size: 13px;
}
.dropdown-toggle::after {
  color: #2c2c2c;
  display: none;

}

  .nav__submenu-toggle {
    margin-left: 5px;
 }



.nav__icons {
  ul {
    display: flex;
    align-items: center;
    list-style: none;

    .nav__item {
      margin-left: -25px;

      .nav__link {
        display: flex;
        align-items: center;
        color: #333;
        text-decoration: none;

        &:hover {
          color: #ff6f61;
        }

        .nav__dropdown-icon {
          margin-left: 5px;
        }

        .nav__icons__cart {
          background-color: #ff6f61;
          color: #fff;
          padding: 5px 10px;
          border-radius: 50%;
          font-size: 12px;
        }
      }
    }
  }
}

h3 {
  font-size: 18px;
}

//<------------------------------------------------>

:root {
  --txt-color: #efefef;
  // --light-color: black;
  --green: black;
  // --dark-color: white;
  --dark-gray: white;
  

  --pad: 1rem;
  --desktop-pad: 3rem;
  --menu-mobile-height: 4rem;
  --menu-icon-size: 1.5rem;
  // --menu-icon-color: var(--light-color);
  --menu-desktop-height: 5rem;
  --menu-desktop-bg: var(--dark-color);
  --menu-desktop-submenu-width: 12rem;
  --menu-desktop-submenu-height: 1rem;
  --menu-desktop-submenu-bg: var(--dark-gray);
  --menu-mobile-color: var(--green);
  --menu-mobile-bg: var(--dark-color);
  --menu-mobile-submenu-bg: var(--dark-color);
}

@media (min-width: 990px) {

  .nav__logo{
    margin-left: -35px;
  }
  .nav__dropdown-icon{
    margin-left: -31px;
  }
  a, a:visited, a:hover, a:active {
    color: inherit;
    margin-left: 27px;
}

  input[type="checkbox"]:checked ~ .menu {
    display: none;
  }

  // .menu ul label::before {
  //   display: none;
  // }
   
  
  .mobile-menu {
    display: none !important;
  }
  
  #touch-menu {
    display: none;
  }
  
  .mobile-submenu {
    display: none;
  }

  .touch-menu1{
    display: none;
  }
  .touch-submenu{
    display: none;
  }
  .D2U{
    display: none;
  }




.container {
  max-width: 1000px;
  margin: 0 auto;
}


nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  text-align: left;
}

nav li {
  display: inline-block;
}

nav a {
  color: #444;
  text-decoration: none;
  display: block;
  padding: .75em 1.75em;
}

nav li:hover {
//   background: #f0ebeb;
color: #ff6f61;
}

nav li:hover {
  color: #ff6f61;
}


.menu-sub {
  position: absolute;
  left: 0;
  background: hsl(0, 0%, 100%);
  width: 100%;
  display: none;
  color: #000000;
  padding: 2em;
  margin-left: 66px;
  border-radius: 44px;
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  -ms-border-radius: 44px;
  -o-border-radius: 44px;
}


nav li:hover .menu-sub {
  display: block;
}

.menu-sub li {
  display: block;
}

.menu-sub a {
  padding: 0;
  margin-bottom: .35em;
}

// .menu-sub a:hover {
//   text-decoration: underline;
// }

.menu-category {
  margin: 2.5em 0 .5em;
}

.menu-category:first-of-type {
  margin-top: 0;
}

.menu-col-1,
.menu-col-2,
.menu-col-3,
.menu-col-4 {
  float: left;
}

.menu-col-1 {
  width: 85%;
}

.menu-col-2 {
  width: 50%;
}

.menu-sub ul li:hover .menu-col-1 {
  display: block;
  animation: menu-sub 1s ease-out;
  -webkit-animation: menu-sub 1s ease-out;
}

@keyframes menu-sub {
  0% {
    opacity: 0;
    margin-top: 50px;
  }
  20% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
    margin-top: -2px;
  }
  100% {
    margin-top: 0;
  }
}
a {
  // font-weight: bold;
  // color: #121212;
  text-decoration: none;
}
#touch-menu:checked ~ .menu {

  > ul {    /* hide scrollbar fix while toggle mobile menu animation for big menus */
  overflow: visible;
  animation: unset;
  }
  }
  /* Animation keyframes */
@keyframes slideDown {
  0% {
  opacity: 0;
  transform: translateY(-20px);
  }
  100% {
  opacity: 1;
  transform: translateY(0);
  }
  }
  
  @keyframes hide-scroll { /* hide scrollbar fix while toggle mobile menu animation for big menus */
  from, to {
  overflow: hidden;
  }
  }

  :root {
  --menu-icon: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMS4xLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgNDI0IDQyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDI0IDQyNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIHN0eWxlPSJmaWxsOiMwMTAwMDI7IiBkPSJNMzUsODlDMTUsODksMCw3NCwwLDU0czE1LTM2LDM1LTM2aDM1M2MyMCwwLDM2LDE2LDM2LDM2cy0xNiwzNS0zNiwzNUgzNXoiLz4KCQk8cGF0aCBzdHlsZT0iZmlsbDojMDEwMDAyOyIgZD0iTTM4OCwxNzZjMjAsMCwzNiwxNiwzNiwzNnMtMTYsMzUtMzYsMzVIMzVjLTIwLDAtMzUtMTUtMzUtMzVzMTUtMzYsMzUtMzZIMzg4eiIvPgoJCTxwYXRoIHN0eWxlPSJmaWxsOiMwMTAwMDI7IiBkPSJNMzg4LDMzNWMyMCwwLDM2LDE1LDM2LDM1cy0xNiwzNi0zNiwzNkgzNWMtMjAsMC0zNS0xNi0zNS0zNnMxNS0zNSwzNS0zNUgzODh6Ii8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  }

  .st0 {
    fill: #00A551;
    }

    .st1 {
    fill: #FFFFFF;
    }

    .st2 {
    fill: #FAAF40;
    }

    .st3 {
    fill: #231F20;
    }

    .st4 {
    fill: #09572F;
    }

    .st5 {
    fill: #006838;
    }

    .logo {
    #eye-l {
    transform-origin: 50% 50%;
    }

    #eye-r {
    transform-origin: 50% 50%;
    }

    }

    @keyframes blink {
    from {
    transform: scaleY(1);
    animation-timing-function: ease-in;
    }

    75% {
    transform: scaleY(.1);
    animation-timing-function: ease-out;
    }

    100% {
    transform: scaleY(1);
    animation-timing-function: ease-in;
    }
    }
}

@media (max-width: 768px) {

  // .nav__dropdown-icon{
  //   margin-left: -34px;
  // }
  .subcategory_image{
    display: none;
  }
  .nav__close{
    display: none;
  }
  .shop__filter__searchHome{
    display: none;
  }
  .nav__logo {
    display: none;
  }
  
  .drop{
    margin-left: 36px;
  }
  /* Mobile Menu Styles */
  .mobile-menu {
    display: block;
    cursor: pointer;
  }
  
  .menu {
    display: none;
    transition: all 0.3s ease-in-out;
    background-color: white;
    width: 100%;
  }
  .menu.open {
    display: block;
  }
  
  .D2U{
    display: block;
    width: 117px;
    margin-left: 11rem;
    margin-top: 10px;
  }
  .container {
    background: var(--menu-mobile-bg);
    height: var(--menu-mobile-height);
    overflow: visible;
    display: inline-block;
    
    label {
      cursor: pointer;
    }
  }
  
  #touch-menu, .menu input[type='checkbox'] {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }
  
  .menu {
   display: grid;
   grid-template-rows: 0fr;
   background: var(--menu-mobile-bg);
   transition: grid-template-rows .5s ease-in-out;
   margin: 0;
   max-height: 100vh;
   overflow: hidden;
   width: 26rem;
   background-color: white;
   margin-top: 4%;
   margin-left: -36px;
  }
  
   a
  {
      text-decoration: none;
      color: black; /* Adjust the color as needed */
    
  
      &:hover {
        // text-decoration: underline;
        color: #ff6f61;
      }
    }
  
    ul {
      overflow: hidden;
      margin: 0;
      padding: 0 var(--pad);
      list-style: none;
      background-color: whitesmoke;
    
      li {
        margin-bottom: 0;
        position: relative;
   
  
        .submenu {
          width: 100%;
          display: grid;
          grid-template-rows: 0fr;
          transition: grid-template-rows .5s ease-in-out;
          margin-left: calc(var(--menu-icon-size) + var(--pad));
          background: var(--menu-mobile-submenu-bg);
          margin-left: 15px;
    
        a {
          margin-left: calc(var(--menu-icon-size) + var(--pad));
          line-height: var(--menu-mobile-height);
          color: var(--menu-mobile-color);
        
        }
      }
    }
    ul {
      overflow: hidden;
      margin-left: 0;
    }
  
      a {
        margin-left: 14px;
    
      }
      

      li {
        overflow: hidden;
      }
    }
  }

  label {
    display: inline-block;
    position: absolute;
    width: var(--menu-icon-size);
    margin: 0;
    top: calc(var(--menu-mobile-height) / 2 - var(--menu-icon-size) / 2);
    transition: transform .5s ease-in-out;
    cursor: pointer; /* Add cursor style for better interaction */


    &::before {
      content: "+";
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: var(--menu-icon-size);
      height: var(--menu-icon-size);
      color: var(--menu-icon-color);
      font-size: var(--menu-icon-size);
    }
    
    &:hover {
      opacity: .7;
    }
  }

  input:checked ~ .submenu {
    grid-template-rows: 1fr;
  }

  // input:checked ~ label {
  //   &::before {
  //     content: "-";
  //   }
  // }
// 	}		
// }

/* Styles for the dropdown input and label icon */
.touch-menu {
  display: none; /* Hide the input checkbox */
}

.mobile-submenu {
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
  user-select: none;
}


/* Style the plus icon */
.mobile-submenu::before {
  content: "+";
}

/* Style the minus icon when the checkbox is checked */
.touch-menu:checked + .mobile-submenu::before {
  content: "-";
}


@media (max-width: 768px) {
/* Add this CSS to your stylesheets */
.menu-mobile-scroll {
  // height: 1200%;
  background: transparent;
}
}
// .hemburger{
//   margin-top: -9px;
// }
/* Add this to your CSS file */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  z-index: 1000; /* Ensure it's above other content */
  transition: opacity 0.3s ease-in-out;
}

.overlay.open {
  display: block; /* Show the overlay when it's open */
}

/* CSS to hide the icon for the "Home" menu item */
.mobile-submenu[data-home="true"] {
  display: none;
}
/* CSS */
.submenu {
  display: block;
}
.submenu.active {
  display: block;
}

/* Close animation for the hamburger icon */
.mobile-menu .hamburger-react.is-active div {
  transform: rotate(0deg); /* Rotate back to the initial state */
  transition: transform 0.3s ease-in-out; /* Add a smooth transition */
}



#touch-menu:checked ~ .menu {
grid-template-rows: 1fr;

> ul {    /* hide scrollbar fix while toggle mobile menu animation for big menus */
  overflow: hidden auto;
  animation: hide-scroll 0.5s backwards;
}
}


.mobile-menu {
// background: var(--menu-mobile-bg);
color: black;
display: flex;
align-items: center;
gap: var(--pad);
margin: 0;
line-height: var(--menu-mobile-height);
height: var(--menu-mobile-height);
padding: -1 var(--pad);
color: black;
height: 3rem;

&::before {
  content: "";
  display: block;
  position: relative;
  width: var(--menu-icon-size);
  height: var(--menu-icon-size);
  background-color: var(--menu-icon-color);
  
  mask-image: var(--menu-icon);
  mask-repeat: no-repeat;
  mask-size: contain;

  -webkit-mask-image: var(--menu-icon);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  margin-left: -23px;

}
}




//<-----------------------poppins style---------------------------------------->























































































//<----------------------ROUGH--------------------------------->

// /* Flyout menu styles */SBFJKABNKJCHnakdhnx

//   .ul-reset {
//     padding-left: 0;
//      margin-top: 0;
//      margin-bottom: 0;
//     list-style: none;
//   }
//   .mega-menu h4 {color: #444;}
// .mega-menu ul {
// 	float: left;
//   	margin-bottom: 20px;
//   	margin-right: 40px;
//   	width: 205px;
// }
// nav {
// 	font-size: 0;
// 	position: relative;
  
// }
// nav > ul > li {
// 	display: inline-block;
//   	font-size: 14px;
//   	padding: 0 15px;
  
// }

// nav > ul > li:hover > a {
// 	color:  #ff6f61;
//   	border-bottom: 3px solid  #ff6f61;
// }




// #mega_menu {
//   display: flex;
//   align-items: center;
// }
// #mega_menu ul {
//   list-style: none;
//   padding: 0;
//   margin: 0;
//   text-align: left;
//   position: relative;
// }

// #mega_menu li a {
//   // display: inline-block;
//   // width: auto;
//   // height: inherit;
//   // line-height: normal!important;
//   // white-space: nowrap;
//   // overflow: hidden;
//   font-size: 16px;
//   cursor: pointer;
//   transition:.2s ease all;
//   color: #262626;
//   padding: 0 23px;
//   font-size: 16px;
//   line-height: 50px;
//   display: block;
//   text-decoration: none;
// }
// #mega_menu ul li {
//   display: inline-block;
// }
// #mega_menu li a:hover {
//   opacity: 0.7;
// }
// #mega {
//   display: none;
//   position: absolute;
//   top: 100%;
//   left: 0;
//   right: 0;
//   z-index: 1;
//   background: #fff;/* Replace with your desired background color */
//   box-shadow: 0 8px 20px -2px rgba(0, 0, 0, 0.32); /* Replace with your desired border color */
//   padding: 15px 0;
//   z-index: 999999;
// }
// #mega_menu ul li:hover #mega {
//   display: block;
//   -webkit-animation-name: megaMenu; /* Safari 4.0 - 8.0 */
//   -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
//   animation-name: megaMenu;
//   animation-duration: 1s;
// }
// .drop-down:hover #mega {
//   display: block;
// }

// /* Nested dropdown styles */
// // ul ul {
// //   display: none;
// //   position: absolute;
// //   top: 0;
// //   left: 100%;
// //   z-index: 2;
// //   background-color: #fff; /* Replace with your desired background color */
// //   border: 1px solid #ccc; /* Replace with your desired border color */
// //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// // }

// .drop-down ul li:hover ul {
//   display: block;
// }
// //<------>
// #mega h3 {
//   margin: 10px 0;
// }


// #mega ul li {
//   display: block;
// }

// #mega ul li a {
//   font-size: 14px;
//   padding: 0 0 0 15px;
//   line-height: 25px;
// }

// #mega ul li:hover {
//   background-color: transparent;
  
// }

// #mega ul li:hover a {
//  color: #ff6f61;
// }


// #mega img {
//   margin-top: 15px;
//   max-height: 150px;
//   box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.5);
// }


// @keyframes megaMenu {
//   0% {
//       opacity: 0;
//       margin-top: 50px
//   }
//   20% {
//       opacity: .25
//   }
//   50% {
//       opacity: 1;
//       margin-top: -2px
//   }
//   100% {
//       margin-top: 0;
//   }
// }



// ANOTHER ONE ---------------------------------------------------------------->


// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

// .navbar {
//   display: flex;
//   align-items: center;
//   background-color: white; /* Replace with your desired background color */
//   padding: 4px;
// }

// .container {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
// }

// ul {
//   list-style: none;
//   display: flex;
//   margin: 0;
//   padding: 0;
// }

// .drop-down {
//   position: relative;
//   margin-right: 20px;
// }

// a {
//   text-decoration: none;
//   color: #333; /* Replace with your desired text color */
//   padding: 10px;
// }
//<---------------------------------------------->