.features__container__items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    /* Additional styles for the grid container */
  }
  
  .features__container__items__item {
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: box-shadow 0.3s, background-color 0.3s;
    /* Additional styles for each item box */
  }
  
  .features__container__items__item:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0; /* Replace with your desired background color */
    /* Additional styles for each item box on hover */
  }