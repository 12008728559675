@use "./utils/" as *;

.login-section {
  height: 100vh;
  @include flex(center, center);
}
.login-section1 {
  height: 170vh;
  @include flex(center, center);
}
.login {
  width: 100%;
  max-width: 350px;
  padding-top: 8rem;
  padding-bottom: 5rem;

  &__title {
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 3.5rem;
    text-align: center;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
      width: 20%;
      height: 2px;
      transition: width 0.5s ease;
      background-color: var(--theme-color);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
}
  }

  &__form {
    .pass {
      position: relative;
      small {
        position: absolute;
        top: 35%;
        right: 15px;
        transform: translateY(-50%);
        cursor: pointer;
        opacity: 0.5;
        svg {
          font-size: 16px;
        }
      }
    }
    &__input {
      height: 45px;
      width: 100%;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
      padding: 0px 20px;
      border-radius: 20px;
      background-color: #f7f7f7;
      text-align: center;
      margin-bottom: 16px;

      &:focus {
        border: 1px solid var(--theme-color);
      }
    }

    &__forgot {
      text-align: end;
      font-size: 14px;
      font-weight: 500;
      color: #f4a51c;
      transition: all 0.5s ease;

      &:hover {
        text-decoration: underline;
      }
    }

    &__social {
      position: relative;

      > div > div > div {
        width: 350px;

        @include breakpoints-down("small") {
          width: 320px;
        }
        @include breakpoints-down("xm") {
          width: 300px;
        }
      }

      > div {
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
      }
      button {
        width: 100%;
        margin: 10px 0;
        height: 50px;
        text-transform: capitalize;
        box-shadow: 0 2px 4px #777;
        margin-top: 1rem;
        border-radius: 25px !important;
        font-size: 15px;

        > span {
          display: block;
          font-weight: 600;
          letter-spacing: 1px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          margin-left: 58px;
          @media screen and(min-width: 376px) {
            margin-left: 75px !important;
          }

          @media screen and(min-width: 440px) {
            margin-left: 95px !important;
          }
        }
      }
    }

    &__submit {
      cursor: pointer;
      border-radius: 5em;
      color: #fff;
      background: var(--theme-color);
      width: 100%;
      display: block;
      padding: 0px 20px;
      height: 45px;
      font-size: 15px;
      font-weight: 500;
      box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
      transition: all 0.5s ease;

      &:hover {
        background-color: #2c2c2c;
      }
    }
  }
}
